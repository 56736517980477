
import { Vue, Component, Ref } from "vue-property-decorator";
import { loginIn } from "../../src/api/modules/login"
import { use } from "vue/types/umd";
@Component({
  name: "Login",
})
export default class Login extends Vue {
  @Ref("form") formRef: any;
  @Ref("btn") btnRef: any;

  private form = {
    username: "",
    password: "",
  };

  private rules = {
    username: [{ required: true, message: "请输入账号", trigger: "change" }],
    password: [{ required: true, message: "请输入密码", trigger: "change" }],
  };

  mounted() {
    console.log("route:", this.$route);
  }

 async onSubmit() {
  var _this =this
    this.formRef.validate(async (valid: boolean) => {
      if (valid) {
        const { username } = this.form;
        const { password } = this.form;
        // if (username === "admin") {
          console.log("user_name:", username)
          console.log("password:", password)
          let requestData = { "username": username, "password": password }
         await loginIn(requestData).then(function (loginResult: { [x: string]: { [x: string]: any; }; }) {
            console.log("【response】登录返回的结果数据为:", loginResult)
            var token = loginResult["access"]
            console.log("获取cookie成功:", token)
            localStorage.setItem("token", "Bearer " + token)
            _this.$message.success("登陆成功");
            _this.$Cookies.set("vue_admin_token", username, { expires: 1 });
            _this.handleTo();
          }).catch(function (error: string) {
            //_this.$message.error("登陆失败");
            console.log("【response】用户登录失败：", error)
          });
        } 
        // else if (username === "visitor") {
        //   this.$message.success("visitor");
        //   this.$Cookies.set("vue_admin_token", "visitor", { expires: 1 });
        //   this.handleTo();
        // } else {
        //   this.$message.warning("请检查账号密码！");
        // }
      // } else {
      //   return false;
      // }
    });
  }

  handleTo() {
    const redirect = this?.$route?.query?.redirect as string;
    if (redirect) this.$router.push(redirect);
    else this.$router.push("/");
  }
}
